<template>
  <div class="media">
    <div class="media-wrapper">
      <!-- <div class="media-banner"></div> -->
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>题库学习</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 考试类型 -->
        <div class="exam">
          <div class="model">
            <div class="model-title">车型选择</div>
            <ul class="model-list">
              <li :class="['model-li', { 'action' : model == 'trolley'}]" @click="model = 'trolley'">
                <img
                  class="model-img"
                  src="../../../assets/zlxctheory/home_icon_wxiaoche@2x.png"
                >
                <img
                  class="model-acimg"
                  src="../../../assets/zlxctheory/home_icon_xiaoche@2x.png"
                >
                <p class="model-sub">小车</p>
              </li>
              <li :class="['model-li', { 'action' : model == 'bus'}]" @click="model = 'bus'">
                <img
                  class="model-img"
                  src="../../../assets/zlxctheory/home_icon_wkeche@2x.png"
                >
                <img
                  class="model-acimg"
                  src="../../../assets/zlxctheory/home_icon_keche@2x.png"
                >
                <p class="model-sub">客车</p>
              </li>
              <li :class="['model-li', { 'action' : model == 'truck'}]" @click="model = 'truck'">
                <img
                  class="model-img"
                  src="../../../assets/zlxctheory/home_icon_whuoche@2x.png"
                >
                <img
                  class="model-acimg"
                  src="../../../assets/zlxctheory/home_icon_huoche@2x.png"
                >
                <p class="model-sub">货车</p>
              </li>
            </ul>
          </div>
          <div class="suject">
            <ul class="suject-title">
              <li :class="['title-li', {'action' : suject == 'branchOne'}]" @click="suject = 'branchOne'">科目一</li>
              <li :class="['title-li', {'action' : suject == 'branchFour'}]" @click="suject = 'branchFour'">科目四</li>
            </ul>
            <ul class="subject-practise">
              <li class="practise" @click="tapChangePracticeType('chapter')" data-practice="chapter">
                <img
                  class="practise-img"
                  src="../../../assets/zlxctheory/home_icon_zhangjie@2x.png"
                >
                <p class="practise-sub">章节练习</p>
              </li>




              <li class="practise" @click="tapChangePracticeType('special')" data-practice="special">
                <img
                  class="practise-img"
                  src="../../../assets/zlxctheory/home_icon_zhuanxiang@2x.png"
                >
                <p class="practise-sub">专项练习</p>
              </li>


              <li class="practise" @click="tapChangePracticeType('simulation')" data-practice="simulation">
                <img
                  class="practise-img"
                  src="../../../assets/zlxctheory/home_icon_quanzhen@2x.png"
                >
                <p class="practise-sub">全真模拟</p>
              </li>



              <li class="practise" @click="tapChangePracticeType('sequence')" data-practice="sequence">
                <img
                  class="practise-img"
                  src="../../../assets/zlxctheory/home_icon_shunxu@2x.png"
                >
                <p class="practise-sub">顺序练习</p>
              </li>
              <li class="practise" @click="tapChangePracticeType('random')" data-practice="random">
                <img
                  class="practise-img"
                  src="../../../assets/zlxctheory/home_icon_suiji@2x.png"
                >
                <p class="practise-sub">随机练习</p>
              </li>
            
          
              <!-- <li class="practise" @click="tapChangePracticeType('puzzle')" data-practice="puzzle">
                <img
                  class="practise-img"
                  src="../../../assets/zlxctheory/home_icon_zhuanti@2x.png"
                >
                <p class="practise-sub">难题练习</p>
              </li> -->
      
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Download',
  data () {
    return {
      // 车型选择
      model: 'trolley',
      // 科目选择
      suject: 'branchOne',
      // 练习类型
      practiceType: '',
    }
  },
  computed: {
  },
  created () {},
  methods: {
    // 改变练习类型
    tapChangePracticeType(type) {
      const _practiceType = type
      this.practiceType = _practiceType
      switch(_practiceType) {
          case 'sequence':
            this.$router.push({
              name:'Practise',
              query: {
                carType: this.model,
                stepType: this.suject,
                isOrder: 'order',
                exerciseType: 'sequence'
              }
            })
            break;
          case 'random':
            this.$router.push({
              name:'Practise',
              query: {
                carType: this.model,
                stepType: this.suject,
                isOrder: 'noorder',
                exerciseType: 'random'
              }
            })
            break;
          case 'chapter':
            this.$router.push({
              name:'ChapterChoice',
              query: {
                carType: this.model,
                stepType: this.suject
              }
            })
            break;
          case 'special':
            this.$router.push({
              name:'SpecialPractice',
              query: {
                carType: this.model,
                stepType: this.suject
              }
            })
            break;
          case 'puzzle':
            this.$router.push({
              name:'Practise',
              query: {
                carType: this.model,
                stepType: this.suject,
                isOrder: 'order',
                exerciseType: 'puzzle',
                hardLevel: 5
              }
            })
            break;
          case 'simulation':
            this.$router.push({
              name:'RealisticSimulation',
              query: {
                carType: this.model,
                stepType: this.suject
              }
            })
            break;
          default:
            console.log("something else!")
      } 
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
